import {graphql} from "gatsby";
import React from "react";

import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import AboveFooter from "../../components/pages/caseStudy/AboveFooter";
import BottomCTA from "../../components/pages/caseStudy/BottomCTA";
import Hero from "../../components/pages/caseStudy/Hero";
import Para from "../../components/pages/caseStudy/Para";
import Quote from "../../components/pages/caseStudy/Quote";
import QuoteWithImage from "../../components/pages/caseStudy/QuoteWithImage";
import Stats from "../../components/pages/caseStudy/Stats";
import "../../pages/styles.scss";

const ContentComponentMap = {
  csPara: Para,
  csStats: Stats,
  csQuoteWithImage: QuoteWithImage,
  csQuote: Quote,
};

const CaseStudyPage = ({data, pageContext: {data: cmsData}}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {hero, contents, bottomCTA} = cmsData;

  return (
    <Layout
      type='modemagic'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true, alternateBg: true}}
    >
      <StructuredData />
      <Buffer buffer={66} mobileBuffer={30} />
      <Hero data={hero} />
      {contents.map((content) => {
        const {_type: type} = content;
        const ContentComponent = ContentComponentMap[type];
        return <ContentComponent data={content} />;
      })}
      <BottomCTA data={bottomCTA} />
      <AboveFooter />
    </Layout>
  );
};

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default CaseStudyPage;
