import {StyleExtractor} from "@kubric/utils";
import React from "react";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Para = ({data}) => {
  const {heading = "", _rawContent = []} = data || {};

  return (
    <div className={styler.get("paraWrap", "full-bleed")}>
      {heading && <div className={styler.get("heading")}>{heading}</div>}
      <SanityRichText
        blocks={_rawContent}
        renderContainerOnSingleChild
        className={styler.get("content")}
      />
    </div>
  );
};

export default Para;
