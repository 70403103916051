import {StyleExtractor} from "@kubric/utils";
import React from "react";

import AboveFooterSection from "../../../AboveFooterSection";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const AboveFooter = () => (
  <div className={styler.get("aboveFooterWrap", "full-bleed")}>
    <AboveFooterSection hideTicketSectionInDesktop />
  </div>
);

export default AboveFooter;
