import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Quote = ({data}) => {
  const {author, bgColor, designation, quote} = data || {};

  return (
    <div
      className={styler.get("quoteWrap", "full-bleed")}
      style={{backgroundColor: bgColor}}
    >
      <div className={styler.get("quote")}>
        <p>{quote}</p>
        <div className={styler.get("cite")}>
          <div className={styler.get("author")}>{author}</div>
          <div className={styler.get("designation")}>{designation}</div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
