import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const BottomCTA = ({data}) => {
  const {cta, heading, _rawBelowCta} = data || {};

  return (
    <div className={styler.get("bottomCTAWrap", "full-bleed")}>
      <h1 className={styler.get("heading")}>{heading}</h1>
      <OutboundLink
        className={styler.get("cta")}
        href={cta?.url}
        id={cta?.idAttribute}
      >
        {cta?.text}
      </OutboundLink>
      {_rawBelowCta && (
        <SanityRichText
          blocks={_rawBelowCta}
          renderContainerOnSingleChild
          className={styler.get("belowCTA")}
        />
      )}
    </div>
  );
};

export default BottomCTA;
