import {StyleExtractor} from "@kubric/utils";
import React from "react";
import PlayCircle from "react-feather/dist/icons/play-circle";

import SanityVideo from "../../../commons/SanityVideo";
import * as styles from "./styles.module.scss";

const Hero = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {heading, showVideo, video} = data;

  const [playVideo, setPlayVideo] = React.useState(false);

  return (
    <div className={styler.get("heroWrap", "full-bleed")}>
      <h1 className={styler.get("heading")}>{heading}</h1>
      {showVideo && (
        <div className={styler.get("videoContainer")}>
          <SanityVideo
            playVideo={playVideo}
            data={video}
            theme={{container: styler.get("video")}}
          />
          {!playVideo && showVideo && video?.showThumbnail && (
            <PlayCircle
              className={styler.get("playIcon")}
              onClick={() => setPlayVideo(!playVideo)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Hero;
