import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const QuoteWithImage = ({data}) => {
  const {author, bgColor, designation, quote, image} = data || {};

  return (
    <div
      className={styler.get("quoteWithImageWrap", "full-bleed")}
      style={{backgroundColor: bgColor}}
    >
      <div className={styler.get("card")}>
        <div className={styler.get("quote")}>
          <p>{quote}</p>
          <div className={styler.get("cite")}>
            <div className={styler.get("author")}>{author}</div>
            <div className={styler.get("designation")}>{designation}</div>
          </div>
        </div>
        {image?.image?.asset?.gatsbyImageData && (
          <GatsbyImage
            className={styler.get("image")}
            image={image?.image?.asset?.gatsbyImageData}
            alt={image?.alt || "quote image"}
            objectFit='contain'
          />
        )}
      </div>
    </div>
  );
};

export default QuoteWithImage;
