import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Stats = ({data}) => {
  const {stats = []} = data || {};

  return (
    <div className={styler.get("statsWrap", "full-bleed")}>
      <div className={styler.get("stats")}>
        {stats.map(({auditStatValue: value, auditTitleValue: title}) => (
          <div className={styler.get("stat")}>
            <p className={styler.get("value")}>{value}</p>
            <p className={styler.get("text")}>{title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
